<template>
  <div>
    <label v-if="showTitle">
      <i class="fa fa-tags mr-1" /> Tipo de Histórico
    </label>
    <div class="d-flex justify-content-around">
      <v-select
        label="nome"
        :style="showManager ? 'min-width: 80%' : 'min-width: 100%'"
        :options="tiposHistorico"
        :isRequired="true"
        :clearable="true"
        :isClearable="false"
        :multiple="multiple"
        :reduce="
          (v) => {
            return this.returnObject ? v : v.id;
          }
        "
        v-model="historico_id"
      >
        <template v-slot:option="option">
          <span
            class="text-success"
            :class="{ 'text-danger': option.tipo == -1 }"
          >
            {{ option.nome
            }}<small class="ml-3">
              ({{ option.tipo == -1 ? "Débito" : "Crédito" }})</small
            >
          </span>
        </template>
      </v-select>

      <b-btn
        size="sm"
        @click="$bvModal.show('modal-tipo-hist-select')"
        v-if="showManager"
        ><i class="fa fa-cogs" />
      </b-btn>
      <b-modal
        id="modal-tipo-hist-select"
        size="lg"
        @hidden="carregarTiposHistorico"
        title="Gerenciar Tipos de Histórico"
        hide-footer
      >
        <tipo-historico />
      </b-modal>
    </div>
    <div class="text-right w-100" :class="{'pr-6':showManager}" style="font-size: 10px" v-if="multiple">
      <a @click.prevent="limpar" class="cursor"><b-icon-x></b-icon-x>Limpar</a>
    </div>
  </div>
</template>

<script>
import TipoHistoricoLib from "../../../libs/BancoTipoHistoricoLib";
import TipoHistorico from "../../../views/Configuracoes/Financeiro/TipoHistorico.vue";

export default {
  components: { TipoHistorico },
  props: {
    returnObject: {
      type: Boolean,
      default: false,
    },
    tipoHistoricoID: [String, Number, Array],
    showTitle: {
      type: Boolean,
      default: false,
    },
    showManager: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    startSelected: {
      type: Boolean,
      default: false,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregarTiposHistorico();
    this.historico_id = this.tipoHistoricoID;
  },
  data() {
    return {
      historico_id: null,
      tiposHistorico: [],
    };
  },
  watch: {
    tipoHistoricoID: function () {
      // console.log("recebeu tipoHistoricoID", this.tipoHistoricoID)
      this.historico_id = this.tipoHistoricoID;
    },
    historico_id: function () {
      this.$emit("change", this.historico_id);
    },
  },
  computed: {},
  methods: {
    limpar() {
      this.historico_id = null;
      this.$emit("change", this.historico_id);
      this.$forceUpdate();
      console.log(123)
    },
    async carregarTiposHistorico() {
      this.tiposHistorico = (await TipoHistoricoLib.getTipoHistorico()).data;
      if (this.startSelected) {
        if (!this.multiple) this.historico_id = this.tiposHistorico[0].id;
        else {
          this.historico_id = this.tiposHistorico.map((x) => x.id);
          // this.historico_id = this.historico_id.filter(
          //   (x) => ![3, 4, 29, 9].includes(x)
          // );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
